import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import SummerCampContent from '../components/Programmes/SummerCamp'
const SummerCamp = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Summer Camp"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Summer Camp"
            />
            <SummerCampContent />
            <Footer />
        </Layout>
    );
}

export default SummerCamp