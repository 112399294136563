import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'

const SummerCampContent = () => {
    return (
        <section className="about-area ptb-10">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" />
                                    Summer Camp
                                </span>
                                <p> Welcome to our adult summer camp! Our camp is designed for adults who want to relive the fun and adventure
                                    of their childhood summer camps, while also enjoying the freedom and independence of adulthood.</p>
                                <p> Our summer camp offers a variety of activities that cater to a wide range of interests. From outdoor adventures
                                    to creative workshops, our camp is designed to provide a fun and memorable experience for everyone..</p>
                                <h3>Activities:</h3>

                                <p> Our adult summer camp offers a range of activities, including hiking, kayaking, rock climbing, yoga, cooking
                                    classes, and more. We also offer creative workshops such as painting, pottery, and photography, as well as
                                    group games and sports for those who want to relive their camp memories.</p>
                                <h3>Facilities:</h3>

                                <p> Our camp is located in a picturesque natural setting with comfortable accommodations, including cabins and
                                    a main lodge. Our facilities also include a dining hall, outdoor fire pit, and sports fields.</p>
                                <h3>Socializing:</h3>

                                <p> Our camp is designed to be a social experience, with opportunities for campers to connect and build
                                    friendships throughout the week. We offer organized social events, such as campfires, dances, and game nights, as well as plenty of time for campers to relax and socialize on their own.</p>
                                <h3>Dates and Registration:</h3>
                                <p>Our adult summer camp runs for one week in May and June . Registration is now open, and we encourage you to
                                    sign up early to secure your spot. We offer a variety of package options, including full-week and weekend
                                    packages, as well as group discounts for those who register with friends.</p>
                                <p>Our adult summer camp offers a unique and unforgettable experience for those who want to relive the fun and
                                    adventure of their childhood summer camps while enjoying the independence and freedom of adulthood. Join us
                                    for a week of fun, friendship, and adventure, and make memories that will last a lifetime. Register today
                                    and give yourself the gift of a summer camp experience!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>


        </section>
    )
}

export default SummerCampContent;